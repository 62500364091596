import * as THREE from "three"
import * as dat from "lil-gui"
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'


/**
 * Base
 */


// Canvas
const canvas = document.querySelector("canvas.webgl")

// Scene
const scene = new THREE.Scene()


/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load('textures/matcaps/1.png')


/**
 * Sizes
 */

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.left = -1 * sizes.width / sizes.height
    camera.right = 1 * sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})


/**
 * Camera
 */

// Base camera
const camera = new THREE.OrthographicCamera(-1 * sizes.width / sizes.height, 1 * sizes.width / sizes.height, 1, -1, 0.1, 100)
camera.position.x = 1
camera.position.y = 1
camera.position.z = 1
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true


/**
 * Objects
 */
// Front Box
const boxGeometry = new THREE.BoxGeometry(1.2, 1.2, 1.2)
const boxMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })
boxMaterial.wireframe = true
const box = new THREE.Mesh(boxGeometry, boxMaterial)
scene.add(box)

// Back Plane
const planeGeometry = new THREE.PlaneGeometry(1, 1)
const planeMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })
const plane = new THREE.Mesh(planeGeometry, planeMaterial)
plane.position.x = -camera.position.x
plane.position.y = -camera.position.y
plane.position.z = -camera.position.z
plane.lookAt(camera.position)
scene.add(plane)

/**
 * Fonts
 */
const fontLoader = new FontLoader()

fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) =>
    {
        // Material
        const textMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })

        // Text
        let resizeCoefficient = 0.7
        const textGeometry = new TextGeometry(
            'mesq',
            {
                font: font,
                size: 0.5 * resizeCoefficient,
                height: 0.2 * resizeCoefficient,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03 * resizeCoefficient,
                bevelSize: 0.02 * resizeCoefficient,
                bevelOffset: 0,
                bevelSegments: 5
            }
        )
        textGeometry.center()

        const text = new THREE.Mesh(textGeometry, textMaterial)
        scene.add(text)
    }
)


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () => 
{
    const elapsedTime = clock.getElapsedTime()

    // Box Rotation
    box.rotation.x = elapsedTime / 2
    box.rotation.y = elapsedTime / 2

    // Plane Orientation
    plane.quaternion.copy(camera.quaternion)
    plane.position.x = -camera.position.x
    plane.position.y = -camera.position.y
    plane.position.z = -camera.position.z

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()